/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import upperCase from "lodash/upperCase";

import { Spin, Space, Modal, message } from "antd";
import {
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import ContentContainer from "../../../../components/ContentContainer";
import CustomDrawer from "../../../../components/CustomDrawer";
import CustomTable from "../../../../components/CustomTable";
import PdfViewerModal from "../../../../components/PdfViewerModal";
import { userPermission } from "../../../../util/user-access";

import Form from "./forms";

import { Context } from "../../../../context";
import { columns as cols, defaultInitialValue, validationSchema } from "./constant";
import {
    GET_DOCUMENT_LIST,
    GET_PROJECTS_LIST,
    GET_DOCUMENT,
    MODIFY_DOCUMENT,
    REMOVE_DOCUMENT,
} from "../../graphql";
import generateId from "../../../../util/generate-id";

import { Document } from "../../../../entities/Document";
import { DocumentMapper } from "../../dto/DocumentMapper";

const Documents: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { id } = useParams();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:mdor");
    const updateAccess = userPermission(permissions, "update:mdor");
    const deleteAccess = userPermission(permissions, "delete:mdor");

    const [columns, setColumns] = useState<any[]>(cols);
    const [documentsList, setDocumentsList] = useState<Document[]>([]);
    const [projectsList, setProjectsList] = useState<string[]>([]);

    const [viewDocument, setViewDocument] = useState<string>("");
    const [sortBy, setSortBy] = useState<string>("created_at");
    const [sortOrder, setSortOrder] = useState<string>("DESC");
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [showSlider, setShowSlider] = useState<boolean>(false);
    const [reinitialize, setReinitialize] = useState(false);
    const [initialValue, setInitialValue] = useState(defaultInitialValue);

    const { error, refetch, data, loading } = useQuery(GET_DOCUMENT_LIST, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
            page: page,
            limit: pageSize,
            search: search,
            sortBy: sortBy,
            sortOrder: sortOrder,
        },
        fetchPolicy: "no-cache",
        partialRefetch: true,
    });

    const { data: documentData, loading: getDocumentLoading } = useQuery(GET_DOCUMENT, {
        skip: !id || !brandId,
        fetchPolicy: "no-cache",
        variables: {
            id: id,
        },
    });

    const [getProjectsList, { loading: getProjectsListLoading }] = useMutation(GET_PROJECTS_LIST);
    const [modifyDocument, { loading: modifyDocumentLoading }] = useMutation(MODIFY_DOCUMENT);
    const [removeDocument, { loading: removeDocumentLoading }] = useMutation(REMOVE_DOCUMENT);

    const resetForm = () => {
        setInitialValue(defaultInitialValue);
        setReinitialize(true);
    };

    const handleSearch = (query) => {
        query.trim() !== search && setSearch(query.trim());
    };

    const handleSlider = () => {
        setShowSlider(!showSlider);
        if (showSlider) {
            history.push("/manage-mddor-documents");
            resetForm();
        }
    };

    const handleSort = (field: string, order: string) => {
        setSortBy(field);
        setSortOrder(upperCase(order));
    };

    const handlePageSize = async (pageSize: number) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const handleView = (record: Document) => {
        setViewDocument(record.file);
    };

    const handleEdit = (record: Document) => {
        history.push(`${match.path}/${record.id}`);
    };

    const handleDelete = async (record: Document) => {
        const { confirm } = Modal;

        confirm({
            title: "Delete Document?",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this document?",
            async onOk() {
                try {
                    await removeDocument({
                        variables: {
                            id: record.id,
                        },
                    });
                    refetch();
                    message.success("Successfully deleted document");
                } catch {
                    message.error("Something went wrong");
                } finally {
                    return;
                }
            },
            onCancel() {
                return;
            },
        });
    };

    const handleSubmit = async (values) => {
        const data = {
            id: id,
            brand_id: brandId,
            assigned_project: values.assignedProject,
            document_name: values.name,
            file: values.file.keyObj,
            meta_data: {
                key: values.file.keyObj,
                file_name: values.file.name,
                file_type: values.file.type,
            },
        };

        !id && delete data.id;

        try {
            await modifyDocument({
                variables: {
                    data,
                },
            });
            message.success(`Successfully ${id ? "updated" : "added"} document.`);
        } catch (error) {
            message.error(error?.toString());
        } finally {
            handleSlider();
            refetch();
        }
    };

    useEffect(() => {
        const newColumns: any = columns;
        newColumns.find((col) => col.key === "actions").render = (record) => (
            <Space size="middle" style={{ fontSize: "1.5em" }}>
                <SearchOutlined onClick={() => handleView(record)} />
                <EditOutlined
                    style={{ display: !updateAccess ? "none" : "block" }}
                    onClick={() => handleEdit(record)}
                />
                <DeleteOutlined
                    style={{ display: !deleteAccess ? "none" : "block" }}
                    onClick={() => handleDelete(record)}
                />
            </Space>
        );
        setColumns(newColumns);
    }, []);

    useEffect(() => {
        const getProjectValues = async () => {
            try {
                const res = await getProjectsList({
                    variables: {
                        brand: brandName,
                    },
                });

                const projects = res?.data?.mdorProjectListRequest?.map(
                    ({ ProjectName, ProjectCode, BldgCode, PropertyCode }) => ({
                        label: `${ProjectCode} - ${ProjectName} - ${
                            PropertyCode ? PropertyCode : BldgCode
                        }`,
                        value: `${ProjectCode} - ${ProjectName} - ${
                            PropertyCode ? PropertyCode : BldgCode
                        }`,
                    })
                );

                setProjectsList(projects);
            } catch (error) {
                message.error("Could not retrieve projects list.");
            }
        };

        if (data && data.getFilteredMdorDocuments) {
            const { mdor_documents } = data.getFilteredMdorDocuments;
            const res = mdor_documents.map((info: Document) =>
                Object.assign([], DocumentMapper.map({ ...info }))
            );
            setDocumentsList(res);
            setTotal(data.getFilteredMdorDocuments.total_count);
        }

        brandName && getProjectValues();

        if (error) {
            message.error("Something went wrong.");
        }
    }, [data, error]);

    useEffect(() => {
        if (documentData) {
            const { getMdorDocument } = documentData;
            const values = {
                name: getMdorDocument.document_name,
                assignedProject: getMdorDocument.assigned_project,
                file: {
                    uid: generateId(),
                    url: getMdorDocument.file,
                    keyObj: getMdorDocument.meta_data.key,
                    status: "done",
                    name: getMdorDocument.meta_data.file_name,
                    type: getMdorDocument.meta_data.file_type,
                },
            };

            setInitialValue(values);
            setReinitialize(true);
        }
    }, [documentData]);

    useEffect(() => {
        id && setShowSlider(true);
    }, [id]);

    return (
        <ContentContainer>
            <CustomTable
                createAccess={createAccess}
                updateAccess={updateAccess}
                deleteAccess={deleteAccess}
                headingText="List of Documents"
                labelText="documents"
                noStatusBtn={true}
                handleSearch={handleSearch}
                overrideAddLabel={"Upload Document"}
                handleAddBtn={handleSlider}
                columns={columns}
                noAddBtn={!createAccess}
                dataSource={documentsList}
                handleSort={handleSort}
                currentPageSize={pageSize}
                handlePageSize={handlePageSize}
                handlePagination={handlePagination}
                totalData={total}
                loading={loading || removeDocumentLoading || modifyDocumentLoading}
            />

            <CustomDrawer
                visible={showSlider}
                title={`${id ? "Update" : "Upload"} Documents`}
                onClose={handleSlider}
                width={450}
            >
                <Spin spinning={getProjectsListLoading || getDocumentLoading}>
                    <Formik
                        initialValues={initialValue}
                        validationSchema={validationSchema}
                        enableReinitialize={reinitialize}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit(values);
                            resetForm();
                        }}
                        render={(formikBag) => (
                            <Form
                                {...{
                                    formikBag,
                                    handleSlider,
                                    projectList: projectsList,
                                    loading: false,
                                }}
                            />
                        )}
                    />
                </Spin>
            </CustomDrawer>

            <PdfViewerModal
                src={viewDocument}
                visible={viewDocument ? true : false}
                onCancel={() => setViewDocument("")}
            />
        </ContentContainer>
    );
};

export default Documents;
