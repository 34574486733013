import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/upload/objects`;

const uploadFile = (file: File, filename: string): Promise<any> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", filename);

    const data = axios({
        method: "POST",
        url: BASE_URL,
        data: formData,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });

    return data;
};

export default uploadFile;
